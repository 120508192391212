import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "table",
      "style": {
        "position": "relative"
      }
    }}>{`Table`}</h1>
    <p>{`O componente apresenta dados distribuídos em linhas e colunas, apresentando-os de uma forma que permite uma fácil leitura, comparação e escaneamento das informações.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Colunas onde é possível fazer a ordenação devem apresentar o ícone de ordenação ao lado direito da label.`}</li>
      <li parentName="ul">{`O `}<a parentName="li" {...{
          "href": "/components/paginator"
        }}>{`Paginator`}</a>{` só deve ser apresentado quando há paginação (mais de 10 itens).`}</li>
    </ul>
    <h2 {...{
      "id": "tabela-de-dados",
      "style": {
        "position": "relative"
      }
    }}>{`Tabela de dados`}</h2>
    <p>{`Uma tabela que gera automaticamente linhas com base na propriedade de `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`columns`}</code>{` e dá suporte ao estado de ordenação e estados de carregamento.`}</p>
    <Demo src='pages/components/table/DataTable' mdxType="Demo" />
    <h2 {...{
      "id": "tabela-paginada",
      "style": {
        "position": "relative"
      }
    }}>{`Tabela paginada`}</h2>
    <p>{`Uma Tabela de dados que inclui um componente de paginação no rodapé.`}</p>
    <Demo src='pages/components/table/PagedTable' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      